import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Card, Col, Form, Result, Row, Space } from 'antd';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox/Checkbox';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import useMessage from 'antd/lib/message/useMessage';

import PageLayout from 'common/components/PageLayout';
import Company from 'features/signup/components/inputs/Company';
import Email from 'features/signup/components/inputs/Email';
import FirstName from 'features/signup/components/inputs/FirstName';
import LastName from 'features/signup/components/inputs/LastName';
import Password from 'features/signup/components/inputs/Password';
import PaymentCard from 'features/signup/components/PaymentCard';
import PlanSelector from 'features/signup/components/PlanSelector';
import SignupHeader from 'features/signup/components/SignupHeader';
import SubmitButton from 'features/signup/components/SubmitButton';
import { FREE_PRICE, PLANS, VALID_PLANS } from 'features/signup/signup-constants';
import { useFinish, useSubmitForm } from 'features/signup/signup-hooks';
import { PlanType } from 'features/signup/signup-types';

import styles from './SignupPage.module.scss';

const TOS_URL = 'https://www.import.io/legal/terms-conditions';

const planFromUrl = location.hash.substring(1);
const initialPlanType: PlanType = VALID_PLANS.includes(planFromUrl) ? (planFromUrl as PlanType) : PlanType.TRIAL;
const initialValues = { plan: initialPlanType };

const SignupPage = (): JSX.Element => {
  const [agreeCharge, setAgreeCharge] = useState(false);
  const [agreeTOS, setAgreeTOS] = useState(false);

  const [messageApi, messagePlaceholder] = useMessage();
  const [planType, setPlanType] = useState<PlanType>(initialPlanType);
  const { features, price } = useMemo(() => PLANS[planType], [planType]);
  const isFree = price === FREE_PRICE;
  const { form, onSubmit, formRef } = useSubmitForm();
  const { error, isLoading, isSuccess, onFinish } = useFinish({
    formRef: formRef,
    messageApi: messageApi,
  });

  const allAgreed = useMemo(() => {
    return agreeTOS && (isFree || agreeCharge);
  }, [isFree, agreeCharge, agreeTOS]);

  useEffect(() => {
    setAgreeCharge(false);
    setAgreeTOS(false);
  }, [planType]);

  const handleAgreeChargeChange = useCallback((e: CheckboxChangeEvent) => {
    setAgreeCharge(e.target.checked);
  }, []);

  const handleAgreeTOSChange = useCallback((e: CheckboxChangeEvent) => {
    setAgreeTOS(e.target.checked);
  }, []);

  return (
    <PageLayout>
      {isSuccess ? (
        <Result status="success" subTitle="Please check your inbox to verify email" title="Your account is created!" />
      ) : (
        <>
          {messagePlaceholder}
          <Card className={styles.card}>
            <Space className="full-width" direction="vertical" size="large">
              <SignupHeader error={error} isFree={isFree} />
              <form onSubmit={onSubmit} ref={formRef}>
                <Form
                  component="div"
                  form={form}
                  initialValues={initialValues}
                  layout="vertical"
                  onFinish={onFinish}
                  requiredMark={false}
                  scrollToFirstError
                  size="small"
                  validateTrigger="onSubmit"
                >
                  <Row gutter={40}>
                    <Col md={12} sm={24} xs={24}>
                      <PlanSelector features={features} onChange={setPlanType} price={price} />
                    </Col>
                    <Col md={12} sm={24} xs={24}>
                      <Row gutter={20}>
                        <Col md={12} sm={12} xs={24}>
                          <FirstName />
                        </Col>
                        <Col md={12} sm={12} xs={24}>
                          <LastName />
                        </Col>
                      </Row>
                      <Company />
                      <Email />
                      <Password />
                      <PaymentCard isFree={isFree} setFieldValue={form.setFieldValue} />

                      <div className="p-5 bg-gray-light rounded flex flex-col gap-2 mb-4">
                        {!isFree ? (
                          <Checkbox checked={agreeCharge} onChange={handleAgreeChargeChange}>
                            I understand that my card will be charged <br /> {price}
                          </Checkbox>
                        ) : null}
                        <Checkbox checked={agreeTOS} onChange={handleAgreeTOSChange}>
                          I have read and agree to{' '}
                          <a href={TOS_URL} rel="noreferrer" target="_blank">
                            Terms of Service
                          </a>
                        </Checkbox>
                      </div>

                      <SubmitButton disabled={isLoading || !allAgreed} loading={isLoading} />
                    </Col>
                  </Row>
                </Form>
              </form>
            </Space>
          </Card>
        </>
      )}
    </PageLayout>
  );
};

export default SignupPage;
