import React, { StrictMode } from 'react';

import { IS_PRODUCTION } from '@import-io/js-sdk/constants/host-constants';
import { Elements, RecurlyProvider } from '@recurly/react-recurly';
import ConfigProvider from 'antd/lib/config-provider';
import message from 'antd/lib/message';

import { DEFAULT_MESSAGE_DURATION, RECURLY_KEY } from 'common/common-constants';
import { SignupPage } from 'features/signup';

import { theme } from './theme';

message.config({ duration: DEFAULT_MESSAGE_DURATION });
export const App = (): JSX.Element => (
  <StrictMode>
    <RecurlyProvider publicKey={RECURLY_KEY}>
      <ConfigProvider theme={theme}>
        <Elements>
          <SignupPage />
        </Elements>
      </ConfigProvider>
    </RecurlyProvider>
  </StrictMode>
);
