import type { PropsWithChildren } from 'react';
import React from 'react';

import { ENTER_URL, PRIVACY_URL } from '@import-io/js-sdk/constants/host-constants';
import Layout from 'antd/lib/layout';

import { TARGET_BLANK } from 'common/common-constants';
import LogoSvg from 'common/logos/logo-dark-colored.svg';

import styles from './PageLayout.module.scss';

const { Header, Footer, Content } = Layout;

const containerClass = `flex flex-column align-center ${styles.container}`;
const headerClass = `text-center ${styles.header}`;
const footerClass = `flex flex-column align-center ${styles.footer}`;

const PageLayout = ({ children }: PropsWithChildren): JSX.Element => (
  <Layout className={containerClass}>
    <Header className={headerClass}>
      <img alt="Import.io" src={LogoSvg} width={200} />
    </Header>
    <Content className={styles.content}>{children}</Content>
    <Footer className={footerClass}>
      <span>
        <span className={styles.footerText}>Already have an account?&nbsp;</span>
        <a className={styles.footerLink} href={ENTER_URL} target={TARGET_BLANK}>
          Login here
        </a>
      </span>
      <span className="text-center">
        <span className={styles.footerText}>Please read our&nbsp;</span>
        <a className={styles.footerLink} href={PRIVACY_URL} target={TARGET_BLANK}>
          Privacy Policy
        </a>
        <span className={styles.footerText}>&nbsp;to learn more about the way we use your information.</span>
      </span>
    </Footer>
  </Layout>
);

export default PageLayout;
