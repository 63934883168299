export function getRecaptchaToken(): Promise<string> {
  return new Promise((resolve, reject) => {
    window.grecaptcha.ready(function () {
      window.grecaptcha
        .execute('6Lch1O4mAAAAABKDw1IvOpWSt5TQqeYFXGNavi-1', { action: 'submit' })
        .then((token) => {
          resolve(token);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
}
