import React from 'react';

import Form from 'antd/lib/form';
import Input from 'antd/lib/input';

import { getRequiredRule } from 'features/signup/signup-utils';

const LABEL = 'First Name';
const NAME = 'firstName';
const VALIDATION_RULES = [getRequiredRule(LABEL)];

const FirstName = (): JSX.Element => (
  <Form.Item label={LABEL} labelAlign="left" name={NAME} required rules={VALIDATION_RULES}>
    <Input data-recurly="first_name" name={NAME} />
  </Form.Item>
);

export default FirstName;
