import React from 'react';

import Form from 'antd/lib/form';
import Input from 'antd/lib/input';

import { getRequiredRule } from 'features/signup/signup-utils';

const LABEL = 'Password';
const NAME = 'password';
const VALIDATION_RULES = [getRequiredRule(LABEL), { min: 6, message: 'Minimum length is 6 chars' }];

const Password = (): JSX.Element => (
  <Form.Item label={LABEL} labelAlign="left" name={NAME} required rules={VALIDATION_RULES}>
    <Input.Password name={NAME} />
  </Form.Item>
);

export default Password;
