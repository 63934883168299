import Alert from 'antd/lib/alert';
import Typography from 'antd/lib/typography';

import type { SignupHeaderProps } from 'features/signup/signup-types';

const SignupHeader = ({ error, isFree }: SignupHeaderProps): JSX.Element => (
  <>
    <Typography.Title className="text-center" level={4}>
      Getting started {isFree ? 'is free and ' : ''} takes 57 seconds
    </Typography.Title>
    {error ? <Alert message={error} type="error" /> : null}
    {/* TODO: do we really need that? */}
    <div />
  </>
);

export default SignupHeader;
