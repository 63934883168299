import React from 'react';

import Form from 'antd/lib/form';
import Input from 'antd/lib/input';

import { getRequiredRule } from 'features/signup/signup-utils';

const LABEL = 'Last Name';
const NAME = 'lastName';
const VALIDATION_RULES = [getRequiredRule(LABEL)];

const LastName = (): JSX.Element => (
  <Form.Item label={LABEL} labelAlign="left" name={NAME} required rules={VALIDATION_RULES}>
    <Input data-recurly="last_name" name={NAME} />
  </Form.Item>
);

export default LastName;
