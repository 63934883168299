import { ResponseError } from '@import-io/js-sdk';
import { isPresent } from '@import-io/typeguards';

import { RecurlyErrorCode, RecurlyErrorStatus, SignupErrorBody } from 'features/signup/signup-types';

export const getRequiredRule = (text: string) => ({ required: true, message: `${text} is required` });

export const formatError = (message: string): string => `Error creating account: ${message}`;

export const getErrorMessage = (e: unknown): string => {
  const { message, body } = e as ResponseError<SignupErrorBody>;
  const { error, message: bodyMessage = message, recurlyError }: SignupErrorBody = isPresent(body) ? body : { error: message };
  if (isPresent(recurlyError)) {
    const { customerMessage, errorCode, status } = recurlyError;
    return formatError(
      errorCode === RecurlyErrorCode.DECLINED || status === RecurlyErrorStatus.ERROR
        ? customerMessage
        : isPresent(error)
        ? error
        : bodyMessage,
    );
  }

  return formatError(isPresent(error) ? error : bodyMessage);
};
