import { useCallback, useMemo, useState } from 'react';

import type { CardElementChangeEvent } from '@recurly/react-recurly';
import Form from 'antd/lib/form';
import Typography from 'antd/lib/typography';
import classnames from 'classnames';

import CardInput from 'features/signup/components/inputs/CardInput';
import type { PaymentCardProps } from 'features/signup/signup-types';

import styles from './PaymentCard.module.scss';

const NAME = 'card';
const LABEL = 'Card details';

const PaymentCard = ({ isFree, setFieldValue }: PaymentCardProps): JSX.Element => {
  const [isValid, setIsValid] = useState(true);

  const containerClassName = classnames('full-width rounded', styles.container, { [styles.containerInvalid]: !isValid });

  const rules = useMemo(
    () => [
      {
        validator: (rule, value: CardElementChangeEvent) =>
          isFree || value.valid ? Promise.resolve(value) : Promise.reject('Card details are required'),
      },
    ],
    [isFree],
  );

  const onChange = useCallback(
    (e: CardElementChangeEvent) => {
      setFieldValue(NAME, e);
    },
    [setFieldValue],
  );

  if (isFree) {
    return <div />;
  }

  const label = isFree ? (
    <span>
      {LABEL} <Typography.Text type="secondary">(optional)</Typography.Text>
    </span>
  ) : (
    LABEL
  );

  return (
    <div className={`flex flex-column ${containerClassName}`}>
      <Typography.Title level={5}>Secure Payment Info</Typography.Title>
      <Form.Item label={label} labelAlign="left" name={NAME} required={!isFree} rules={rules}>
        <CardInput onChange={onChange} onValidChange={setIsValid} />
      </Form.Item>
    </div>
  );
};

export default PaymentCard;
