import React from 'react';

import Button from 'antd/lib/button';

import type { SubmitButtonProps } from 'features/signup/signup-types';

const SubmitButton = ({ disabled, loading }: SubmitButtonProps): JSX.Element => (
  <Button className="full-width" disabled={disabled} formMethod="submit" htmlType="submit" loading={loading} type="primary">
    Create account
  </Button>
);

export default SubmitButton;
