import React from 'react';

import Divider from 'antd/lib/divider';
import Form from 'antd/lib/form';
import Select from 'antd/lib/select';
import Space from 'antd/lib/space';

import CheckIcon from 'features/signup/components/CheckIcon';
import { SELECT_PLAN_ITEMS } from 'features/signup/signup-constants';
import type { PlanSelectorProps } from 'features/signup/signup-types';

import styles from './PlanSelector.module.scss';

const containerClass = `flex flex-column full-width ${styles.container}`;

const PlanSelector = ({ features, onChange, price }: PlanSelectorProps): JSX.Element => (
  <div className={containerClass}>
    <Form.Item className={styles.select} label="Choose your plan" labelAlign="left" name="plan" required>
      <Select className="full-width plan-select" onChange={onChange} options={SELECT_PLAN_ITEMS} />
    </Form.Item>
    <Divider className={styles.divider} />
    <div className={`full-width bg-gray-light rounded ${styles.features}`}>
      <Space direction="vertical" size={3}>
        {features.map((feature) => (
          <Space key={feature}>
            <CheckIcon />
            {feature}
          </Space>
        ))}
      </Space>
      <Divider style={{ margin: '15px 0' }} />
      <div style={{ fontSize: '15px' }}>
        <b>Price:</b> {price}
      </div>
    </div>
  </div>
);

export default PlanSelector;
