import React from 'react';

import Form from 'antd/lib/form';
import { FormItemProps } from 'antd/lib/form/FormItem';
import Input from 'antd/lib/input';

import { getRequiredRule } from 'features/signup/signup-utils';

const NAME = 'email';

// is required for proper TSC work with rules array
const ITEM_PROPS: FormItemProps = {
  label: 'Work email',
  labelAlign: 'left',
  name: NAME,
  required: true,
  rules: [getRequiredRule('Email'), { type: 'email', message: 'Invalid email' }],
};

const Email = (): JSX.Element => (
  <Form.Item {...ITEM_PROPS}>
    <Input name={NAME} />
  </Form.Item>
);

export default Email;
