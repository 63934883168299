import { useEffect } from 'react';

import { CardElement } from '@recurly/react-recurly';
import Form from 'antd/lib/form';

import type { CardInputProps } from 'features/signup/signup-types';

const CardInput = ({ onChange, onValidChange }: CardInputProps) => {
  const { status } = Form.Item.useStatus();

  useEffect(() => {
    onValidChange(status !== 'error');
  }, [status, onValidChange]);

  return <CardElement onChange={onChange} />;
};

export default CardInput;
